import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Global } from 'src/app/global/Global';

@Injectable({
  providedIn: 'root',
})

export class LeftBarService {
  private _opcionSeleccionada = new BehaviorSubject<number>(0);
  private _subOpcionSeleccionada = new BehaviorSubject<number>(0);
  private _badges = new BehaviorSubject<number[]>([]);
  private _cargandoPedido = new BehaviorSubject<any>(0);

  // 0 => Ninguna sancion
  // 1 => La primera sancion
  // 2 => La seguna sancion
  private _sancioneCuentaCorriente = new BehaviorSubject<number>(0);

  opcionSeleccionada = this._opcionSeleccionada.asObservable();
  subOpcionSeleccionada = this._subOpcionSeleccionada.asObservable();
  badges = this._badges.asObservable();
  cargandoPedido = this._cargandoPedido.asObservable();
  sancioneCuentaCorriente = this._sancioneCuentaCorriente.asObservable()
  
  cambiarOpcion(opcion: number) {
      if(this._sancioneCuentaCorriente.getValue() == 2){
        this.redireccionarPorSancionCuentaCorriente()
        return
      }

      Global.pagarMetodo.detallePedido = false
      this._opcionSeleccionada.next(opcion);
      this._subOpcionSeleccionada.next(0);
      this.agregarNavegacionLocal()
  }

  cargarPedido(cargando:boolean){
    this._cargandoPedido.next(cargando)
  }

  private redireccionarPorSancionCuentaCorriente(){
    this._opcionSeleccionada.next(2)
    this._subOpcionSeleccionada.next(0)
  }

  cambiarSubOpcion(subOpcion: number) {
    if(this._subOpcionSeleccionada.getValue() !=subOpcion) {
      Global.pagarMetodo.detallePedido = false
      this._subOpcionSeleccionada.next(subOpcion);
      this.agregarNavegacionLocal()
    }
  }
  

  agregarNavegacionLocal(){
    const lastNavigation = {
      optionIndex:this._opcionSeleccionada.getValue(),
      subOptionIndex: this._subOpcionSeleccionada.getValue(),
      route:'home'
    }   
    localStorage.setItem('lastNavigation',JSON.stringify(lastNavigation))
  }

  aplicarSancionesCuentaCorriente(sancion:any){
    this._sancioneCuentaCorriente.next(sancion)
  }

  actualizarBadges(badges: number[]) {
    this._badges.next(badges); 
  }
}